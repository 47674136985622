import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

export function NavComponent() {
    const navigate = useNavigate();

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            navigate("/"); 
        } catch (error) {
            console.error("Error al cerrar sesión:", error);
        }
    };

    return (
        <Navbar expand="md" className="bg-body-tertiary">
            <Container fluid>
                <Navbar.Brand>
                    <img src="logo.png" alt="" style={{ height: '40px' }} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="ms-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <Nav.Link onClick={() => navigate(`/`)}>
                            <Button variant="outline-primary">Companies</Button>
                        </Nav.Link>
                        {/* Botón para cerrar sesión */}
                        <Nav.Link>
                            <Button variant="outline-danger" onClick={handleSignOut}>
                                Cerrar Sesión
                            </Button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
